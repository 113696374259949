import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { SharedService } from '../../_services/shared.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute } from '@angular/router';
import { PropertyService } from '../../_services/property.service';
import { UnsubscribeService } from '../../_services/unsubscribe.service';
import { catchError, ignoreElements, map, Observable, of, shareReplay } from 'rxjs';
import { PropertyInfoDTO } from '../../model/propertyInfoDTO';
import { NgxSpinnerModule } from 'ngx-spinner';


@Component({
  selector: 'app-unsubscribe-from-emails',
  standalone: true,
  imports: [CommonModule, FormsModule, NgxSpinnerModule],
  templateUrl: './unsubscribe-from-emails.component.html',
  styleUrl: './unsubscribe-from-emails.component.css'
})
export class UnsubscribeFromEmailsComponent implements OnInit { 


  constructor(
    // private router: Router, 
    private route: ActivatedRoute,
    private unsubscribeService: UnsubscribeService,
    private propertyService: PropertyService,
    public sharedService: SharedService,
    private responsive: BreakpointObserver) {


  }

  email: string = ''; 
  tenantId: string = '';
  propId: string = '';
  offerAliasId: string = '';
  propertyNamePublic: string = '';

  allUpgrades: boolean = false;
  propertyUpgrades: boolean = true;  

  message: string = ''; 
  errorUnsubscribe: boolean = false;  
  

  public propInfo$!: Observable<PropertyInfoDTO>;
  public propErrors$: any;

  ngOnInit(): void {

    this.email =  this.route.snapshot.paramMap.get('email') ? this.route.snapshot.paramMap.get('email') as string : '';
    this.tenantId = this.route.snapshot.paramMap.get('tenant_id') ? this.route.snapshot.paramMap.get('tenant_id') as string : '';
    this.propId =  this.route.snapshot.paramMap.get('prop_id') ? this.route.snapshot.paramMap.get('prop_id') as string : '';
    this.offerAliasId = this.route.snapshot.paramMap.get('alias_id') ? this.route.snapshot.paramMap.get('alias_id') as string : '';
    this.propInfo$ = this.getPropertyInfo(this.propId, this.offerAliasId);

    this.propErrors$ = this.propInfo$.pipe(
                                          shareReplay(1),
                                          ignoreElements(),
                                          catchError((err) => {
                                            // console.log(err);
                                            return of(err)})
                                        );
  
  }

  getPropertyInfo(propertyId: string, offerAliasId: string): Observable<PropertyInfoDTO> {
    return this.propertyService.getPropertyInfo(propertyId, offerAliasId)
      .pipe(
        shareReplay(1),
        map(res => {
          this.propertyNamePublic = res.prop_name_public;
          return res;
        })
      );
  }

  unsubscribeAllOffers(): void {
    if (this.allUpgrades) {
      this.unsubscribeAll();
    } else if (this.propertyUpgrades) {
      this.unsubscribeProperty();
    }
  } 

  unsubscribeAll(): void {
    this.unsubscribeService
    .unsubscribeAll(this.tenantId, this.propId, this.offerAliasId, this.email)
    .pipe(
      shareReplay(1),
      map(res => {
        this.message = '*Unsubscribe successful.';
        this.errorUnsubscribe = false;
        return res;
      }),
      catchError(err => {
        this.message = '*Unable to unsubscribe. Please try again later.';
        this.errorUnsubscribe = true;
        return of(null); // Return fallback observable to allow stream to complete
      })
    )
    .subscribe();
  }

  unsubscribeProperty(): void {

    this.unsubscribeService
    .unsubscribeProperty(this.tenantId, this.propId, this.offerAliasId, this.email)
    .pipe(
      shareReplay(1),
      map(res => {
        this.message = '*Unsubscribe successful.';
        this.errorUnsubscribe = false;
        return res;
      }),
      catchError(err => {
        this.message = '*Unable to unsubscribe. Please try again later.';
        this.errorUnsubscribe = true;
        return of(null); // Return fallback observable to allow stream to complete
      })
    )
    .subscribe();

  }

}
