<div class="timer" [ngStyle]="this.sharedService.customStyle('timer')"
*ngIf="(timeLeft$ | async) as t; else sorry">
	<div [ngClass]="this.responsiveStyle({ 'vertical': 'timer-text-handset', 'horizontal': 'timer-text',})" >
		<span [hidden]="this.expired">Offer Expires In: </span>
		<span [hidden]="!this.expired" class="expired">Sorry, Offer Expired: </span>
		&nbsp;	
	</div>

	<div [hidden]="this.expired" [ngClass]="this.responsiveStyle({ 'vertical': 'timer-text-handset', 'horizontal': 'timer-text',})">
		<span id="days" [hidden]="t.daysToDday<=0"> {{t.daysString}} </span><span [hidden]="t.daysToDday<=0">Day(s)</span>
		<span id="hours" > {{t.hoursString}} </span><span>Hrs</span>
		<span id="minutes" > {{t.minutesString}} </span><span >Min</span>
		<span id="seconds"> {{t.secondsString}} </span><span>Sec</span>
	</div>

	<div [hidden]="!this.expired" [ngClass]="this.responsiveStyle({ 'vertical': 'timer-text-handset', 'horizontal': 'timer-text',})" class="expired" >		
		<span id="days" > {{" 0"}} </span><span>Day(s)</span>
		<span id="hours" > {{" 0"}} </span><span>Hrs</span>
		<span id="minutes" > {{" 0"}} </span><span>Min</span>
		<span id="seconds" > {{" 0"}} </span><span>Sec</span>
	</div>

</div>

<ng-template #sorry>
	Sorry, something went wrong...
</ng-template>