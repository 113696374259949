import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { map, Observable, shareReplay } from 'rxjs';
import { PropertyInfoDTO } from '../model/propertyInfoDTO';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {

  private domain: string = '';
  private guestOfferDomain: string = '';
  private _url: string = '';

  constructor(    
 
      private httpService: HttpService) {   
        this.domain = environment.domain;
        this.guestOfferDomain = environment.guestOfferDomain;
        this._url = this.domain + 'unsubscribe/';
    }


    // /all/<uuid:alias_id>/<uuid:prop_id>/<uuid:tenant_id>
  public unsubscribeAll(tenant_id: string, prop_id: string, offer_alias_id: string, email: string): Observable<any> {
    const url = this._url + 'all/' + offer_alias_id + '/' + prop_id + '/' + tenant_id

    const body = JSON.stringify({
      "email": email
    });

    return this.httpService.post(url, body).pipe(
      shareReplay(1),
      map(res => {
        console.log(res);
        return res;
      })
    );
  }

  public unsubscribeProperty(tenant_id: string, prop_id: string, offer_alias_id: string, email: string): Observable<any> {
    const url = this._url + 'category/' + offer_alias_id + '/' + prop_id + '/' + tenant_id
    const body = JSON.stringify({
      "email": email
    });
    return this.httpService.post(url, body).pipe(
      shareReplay(1),
      map(res => {
        console.log(res);
        return res;
      })
    );
  }
}
