import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { map, Observable, shareReplay } from 'rxjs';
import { PropertyInfoDTO } from '../model/propertyInfoDTO';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  private domain: string = '';
  private guestOfferDomain: string = '';
  private _url: string = '';

  constructor(    
 
      private httpService: HttpService) {   
        this.domain = environment.domain;
        this.guestOfferDomain = environment.guestOfferDomain;
        this._url = this.domain + 'property/';
    }

  public getPropertyInfo(prop_id: string, offer_alias_id:string): Observable<PropertyInfoDTO> {
    const url = this._url + 'info/' + offer_alias_id + '/' + prop_id
    return this.httpService.get(url).pipe(
      shareReplay(1),
      map(res => {
        let result: PropertyInfoDTO = {...res}
        return result;
      })
    );
  }


}
