import { ApplicationConfig, Injectable, Renderer2, importProvidersFrom} from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule, provideHttpClient, withInterceptors, withXsrfConfiguration } from '@angular/common/http';
import { LoadingInterceptor } from './_interceptors/Loading.interceptor';
import { provideServerRendering } from '@angular/platform-server';
import { NgcCookieConsentConfig, provideNgcCookieConsent } from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import Utils from './_utils/utils';
import { SharedService } from './_services/shared.service';



@Injectable({
  providedIn: 'root'
})

export class AppConfig {

}

// export 
function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export let horizontalOverrideHTML = `
<div role="dialog" aria-live="polite" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" class="cc-window cc-banner cc-type-opt-out cc-theme-edgeless cc-bottom" 
style="background-color: rgb(45, 45, 45); height: 65px;">
  <span id="cookieconsent:desc" class="cc-message" style="color:white">
    By using this site, you acknowledge that you have read and understand the  
    <a style="color:#2a9fd6;" aria-label="learn more about privacy policy" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}privacyPolicy" target="_blank" rel="noopener">privacy policy</a> and 
    <a style="color:#2a9fd6;" aria-label="learn more about terms of service" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}termsOfService" target="_blank" rel="noopener">terms of service</a>
  </span>
  <div class="cc-compliance cc-highlight">
    <button aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny cc-btn-custom cc-deny-custom">Decline</button>   
    <button style="margin-left: 15px; margin-right: 15px;" aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-allow cc-btn-custom cc-allow-custom">Allow cookies</button>
  </div>
</div>
`;

export let horizontalDarkOverrideHTML = `
<div role="dialog" aria-live="polite" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" class="cc-window cc-banner cc-type-opt-out cc-theme-edgeless cc-bottom" 
style="background-color: white; height: 65px;">
  <span id="cookieconsent:desc" class="cc-message" style="color:black">
    By using this site, you acknowledge that you have read and understand the  
    <a style="color:#2a9fd6;" aria-label="learn more about privacy policy" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}privacyPolicy" target="_blank" rel="noopener">privacy policy</a> and 
    <a style="color:#2a9fd6;" aria-label="learn more about terms of service" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}termsOfService" target="_blank" rel="noopener">terms of service</a>
  </span>
  <div class="cc-compliance cc-highlight">
    <button aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny cc-btn-custom cc-deny-custom">Decline</button>   
    <button style="margin-left: 15px; margin-right: 15px;" aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-allow cc-btn-custom cc-allow-custom">Allow cookies</button>
  </div>
</div>
`;


export let horizontalCustomOverrideHTML = `
<div role="dialog" aria-live="polite" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" class="cc-window cc-banner cc-type-opt-out cc-theme-edgeless cc-bottom" 
style="background-color: rgb(45, 45, 45); height: 65px;">
  <span id="cookieconsent:desc" class="cc-message" style="color:white">
    By using this site, you acknowledge that you have read and understand the  
    <a style="color:#2a9fd6;" aria-label="learn more about privacy policy" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}privacyPolicy" target="_blank" rel="noopener">privacy policy</a> and 
    <a style="color:#2a9fd6;" aria-label="learn more about terms of service" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}termsOfService" target="_blank" rel="noopener">terms of service</a>
  </span>
  <div class="cc-compliance cc-highlight">
    <button aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny cc-btn-custom cc-deny-custom">Decline</button>   
    <button style="margin-left: 15px; margin-right: 15px;" aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-allow cc-btn-custom cc-allow-custom">Allow cookies</button>
  </div>
</div>
`;

export let verticalOverrideHTML = `
<div role="dialog" aria-live="polite" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" class="cc-window cc-banner cc-type-opt-out cc-theme-edgeless cc-bottom" 
     style="position: fixed; bottom: 0; left: 0; right: 0; background-color: rgb(45, 45, 45); color: #fff; padding-top: 10px; padding-bottom: 10px; font-size: 14px; z-index: 1000;">
  <div style="display: flex; flex-direction: column; align-items: center;">
    <span id="cookieconsent:desc" class="cc-message" style="color:white">By using this site, you acknowledge that you have read and understand our 
      <a style="color:#2a9fd6;" aria-label="learn more about privacy policy" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}privacyPolicy" target="_blank" rel="noopener">privacy policy</a> & 
      <a style="color:#2a9fd6;" aria-label="learn more about terms" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}termsOfService" target="_blank" rel="noopener">terms of service</a>.
    </span>  

    <div style="margin-top: 0px; display: flex; justify-content: center; flex-wrap: wrap;">
      <button aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny cc-btn-custom cc-deny-custom">Decline</button>
      <button style="margin-left: 10px;" aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-allow cc-btn-custom cc-allow-custom">Allow</button>
    </div>
  </div>
</div>
`;

export let verticalDarkOverrideHTML = `
<div role="dialog" aria-live="polite" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" class="cc-window cc-banner cc-type-opt-out cc-theme-edgeless cc-bottom" 
     style="position: fixed; bottom: 0; left: 0; right: 0; background-color: white; color: black; padding-top: 10px; padding-bottom: 10px; font-size: 14px; z-index: 1000;">
  <div style="display: flex; flex-direction: column; align-items: center;">
    <span id="cookieconsent:desc" class="cc-message" style="color:black">By using this site, you acknowledge that you have read and understand our 
      <a style="color:#2a9fd6;" aria-label="learn more about privacy policy" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}privacyPolicy" target="_blank" rel="noopener">privacy policy</a> & 
      <a style="color:#2a9fd6;" aria-label="learn more about terms" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}termsOfService" target="_blank" rel="noopener">terms of service</a>.
    </span>  

    <div style="margin-top: 0px; display: flex; justify-content: center; flex-wrap: wrap;">
      <button aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny cc-btn-custom cc-deny-custom">Decline</button>
      <button style="margin-left: 10px;" aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-allow cc-btn-custom cc-allow-custom">Allow</button>
    </div>
  </div>
</div>
`;

export let verticalCustomOverrideHTML = `
<div role="dialog" aria-live="polite" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" class="cc-window cc-banner cc-type-opt-out cc-theme-edgeless cc-bottom" 
     style="position: fixed; bottom: 0; left: 0; right: 0; background-color: rgb(45, 45, 45); color: #fff; padding-top: 10px; padding-bottom: 10px; font-size: 14px; z-index: 1000;">
  <div style="display: flex; flex-direction: column; align-items: center;">
    <span id="cookieconsent:desc" class="cc-message" style="color:white">By using this site, you acknowledge that you have read and understand our 
      <a style="color:#2a9fd6;" aria-label="learn more about privacy policy" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}privacyPolicy" target="_blank" rel="noopener">privacy policy</a> & 
      <a style="color:#2a9fd6;" aria-label="learn more about terms" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}termsOfService" target="_blank" rel="noopener">terms of service</a>.
    </span>  

    <div style="margin-top: 0px; display: flex; justify-content: center; flex-wrap: wrap;">
      <button aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny cc-btn-custom cc-deny-custom">Decline</button>
      <button style="margin-left: 10px;" aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-allow cc-btn-custom cc-allow-custom">Allow</button>
    </div>
  </div>
</div>
`;



// overrideHTML docs
// https://www.osano.com/cookieconsent/documentation/javascript-api/
const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.guestOfferDomain,
    // domain: 'localhost' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  // palette: {
  //   popup: {
  //     background: 'rgb(45, 45, 45);'
  //   },
  //   button: {
  //     background: '#dd1d54'
  //   }
  // },
  // theme: 'edgeless',
  // type: 'opt-out',
  // layout: 'my-custom-layout'

  overrideHTML: horizontalOverrideHTML
  
  ,content:{
    message: 'Upgrade offers are provided on behalf of [hotel] by Prestay.luxe, a trusted hotel services partner.  Review our Privacy Policy and Terms of Service. ',  
    link: ' <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="'+ environment.guestOfferDomain + 'privacyAndTerms' +  '" target="_blank" rel="noopener">privacy policy.</a>' 
  }

};

const cookieConsentDeclined = document.cookie
  .split(';')
  .map(cookie => cookie.trim())
  .some(cookie => cookie.startsWith('cookieConsentLuxePricing=declined'));

const cookieConsentAccepted = document.cookie
  .split(';')
  .map(cookie => cookie.trim())
  .some(cookie => cookie.startsWith('cookieConsentLuxePricing=accepted'))

if (cookieConsentDeclined || cookieConsentAccepted) {
  // If the consent cookie is set to 'declined', disable the auto popup.
  cookieConfig.autoOpen = false;
}


export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), 
              provideClientHydration(), 
              provideAnimations(), 
              provideHttpClient(withInterceptors([LoadingInterceptor])),
              provideNgcCookieConsent(cookieConfig),
              {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
              },
              {
                provide: TranslateModule,
                useFactory: (translateLoader: TranslateLoader) => TranslateModule.forRoot({ loader: { provide: TranslateLoader, useValue: translateLoader } }),
                deps: [TranslateLoader]
              }
            ]
};




