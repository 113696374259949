import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { interval, map, Observable, shareReplay } from 'rxjs';
import { SharedService } from '../../_services/shared.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import Utils from '../../_utils/utils';
import { ResponsiveMode } from '../../enum/responsiveModes';

interface timeComponents {
  secondsToDday: number;
  minutesToDday: number;
  hoursToDday: number;
  daysToDday: number;

  secondsString: string;
  minutesString: string;
  hoursString: string;
  daysString: string;

}

@Component({
  selector: 'app-count-down-timer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './count-down-timer.component.html',
  styleUrl: './count-down-timer.component.css'
})
export class CountDownTimerComponent implements OnInit {


  viewMode: string = ResponsiveMode.horizontal;

  @Input()
  title: string = 'Offer Expires In:';  

  @Input()
  endDate: Date = new Date(2025, 3, 27);

  expired: boolean = false; 

  public timeLeft$: Observable<timeComponents>;

  constructor(
    private responsive: BreakpointObserver,
    public sharedService: SharedService) {
    this.timeLeft$ = interval(1000).pipe(
      map(x => this.calcDateDiff()),
      shareReplay(1)
    );
  }

  ngOnInit(): void {

    this.responsive.observe([
      Breakpoints.HandsetPortrait,
    ])
      .subscribe(result => {

        const breakpoints = result.breakpoints;

        if(breakpoints[Breakpoints.HandsetPortrait]){
          this.viewMode = ResponsiveMode.vertical;
        }else {
          this.viewMode = ResponsiveMode.horizontal;
        }          
      });

  }

  calcDateDiff(): timeComponents {
    const dDay = this.endDate.valueOf();
  
    const milliSecondsInASecond = 1000;
    const hoursInADay = 24;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;

    const timeDifference = Math.max(dDay - (Date.now()), 0);

    const daysToDday = Math.floor(
      timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay)
    );
  
    const hoursToDday = Math.floor(
      (timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
        hoursInADay
    );
  
    const minutesToDday = Math.floor(
      (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
        secondsInAMinute
    );
  
    const secondsToDday =
      Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;
  
    let secondsString: string = secondsToDday.toString(); 
    let minutesString = minutesToDday.toString();
    let hoursString = hoursToDday.toString();
    let daysString = daysToDday.toString();

    if(secondsToDday <= 9) {
      secondsString = "0" + secondsString;
    } else if(minutesToDday <= 9){
      minutesString = "" + minutesString;
    } else if(hoursToDday <= 9) {  
      hoursString = "" + hoursString;
    }
    else if(daysToDday <= 9) {
      daysString = "" + daysString;
    }
    if(timeDifference === 0){
      this.title = 'Offer Expired';
      this.expired = true;
      return { secondsToDday: 0, minutesToDday: 0, hoursToDday: 0, daysToDday: 0,
              secondsString: " 0", minutesString: " 0", hoursString: " 0", daysString: " 0"

       }; 
      
    } else {
      
      return { secondsToDday: secondsToDday, minutesToDday: minutesToDday, hoursToDday: hoursToDday, daysToDday: daysToDday,
              secondsString: secondsString, minutesString: minutesString, hoursString: hoursString, daysString: daysString

       };
    }


  }

  responsiveStyle(styleObj: {[key: string]: string}): {[key: string]: boolean} {   
    return Utils.responsiveStyle(this.viewMode, styleObj)
  } 

}
