
<ngx-spinner [ngStyle]="this.sharedService.customStyle('spinnerTheme')" type="square-jelly-box"><h3 [ngStyle]="this.sharedService.customStyle('spinnerTheme')">Loading...</h3></ngx-spinner>
<div [ngStyle]="this.sharedService.customStyle('bodyTheme')" style="height: auto;"  *ngIf="{propInfo: propInfo$ | async, error: propErrors$ | async} as data" >                        
    <div [hidden]="data.error ? true : false" > 
        <div *ngIf="data.propInfo" style="width: 100vw; height: 100vh;">
            <div style="                    
                        /* vertical center */
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        /* vertical center */

                        width: auto;
                        min-width: 380px !important;
                        max-width: 490px !important;
                        height: auto;
                        max-height: 221 !important;
                        font-size: large;
                        text-align: center;" >
                <div class="unsubscribe-container">

                    <h4 style="text-align: left;">Unsubscribe From Upgrade Offers</h4>
                        <div style="margin-left: 5px;">
                            
                            <div class="div-unsubscribe">
                                <label>
                                  <input class="checkbox-unsubscribe" type="checkbox" [(ngModel)]="allUpgrades" name="allUpgrades" />
                                  &nbsp;
                                  All properties using this service
                                </label>
                            </div>
                              
                            <div class="div-unsubscribe">
                                <label>
                                  <input class="checkbox-unsubscribe" type="checkbox" [(ngModel)]="propertyUpgrades" name="propertyUpgrades" />
                                  &nbsp;
                                  {{data.propInfo.prop_name_public | titlecase}}
                                </label>
                            </div>
    
                            <div class="div-unsubscribe">
                                <button                             
                                class="button-request" #buttonUnsubscribeProperty 
                                    [ngStyle]="this.sharedService.customStyle('buttonRequestTheme')"
                                    style="margin-left: 0px;"
                                    (click)="this.unsubscribeAllOffers()">Unsubscribe
                                </button>
                            </div>
                    
                        </div>

                        <span class="message" [ngClass]="{ 'warning': errorUnsubscribe}" >{{this.message}}</span>
                    <!-- Display a message after submission -->
                    <!-- <div *ngIf="message" class="alert alert-info" style="margin-top: 10px;">
                    {{ message }}
                    </div> -->
                </div>

            </div>
        </div>
    </div>
    <!-- <app-offer-not-available [hidden]="data.error ? false : true"></app-offer-not-available>                -->
</div> 
