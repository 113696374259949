<ngx-spinner [ngStyle]="this.sharedService.customStyle('spinnerTheme')" type="ball-grid-pulse" size="medium" [fullScreen]="true">
    <h3 [ngStyle]="this.sharedService.customStyle('spinnerTheme')">Loading...</h3>
  </ngx-spinner>
  <div class="page-wrapper">
    <div class="content" [hidden]="isLoading" [ngStyle]="this.sharedService.customStyle('bodyTheme')" style="height: 100%;" *ngIf="{offers: offers$ | async, error: offerErrors$ | async} as data">
      <div [hidden]="data.error ? true : false">
        <app-offer-card-list-responsive #offerList [offers]="data.offers" [offerCount]="this.offerCount"></app-offer-card-list-responsive>
        
  
      </div>
      <app-offer-not-available [hidden]="this.handleError(data.error) ? false : true"></app-offer-not-available>
    </div>

  </div>

  <div [ngStyle]="this.sharedService.customStyle('footerTheme')">
    <p 
    id="privacy_and_terms"
    class="footer"
    [ngClass]="this.responsiveStyle({ 'vertical': 'other-options-handset', 'horizontal': 'other-options-desktop'})">
    <span>Upgrade offers are provided on behalf of {{prop_name | titlecase}} by Prestay.luxe, a trusted hotel services partner. Review our
      <a [ngStyle]="this.sharedService.customStyle('txtAnchorTheme')"  
         class="anchorize" 
         (click)="navigatePrivacy()">Privacy Policy</a>
      and
      <a [ngStyle]="this.sharedService.customStyle('txtAnchorTheme')"  
         class="anchorize" 
         (click)="navigateTerms()">Terms of Service</a>
    </span>
  </p>

  </div>